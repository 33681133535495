@charset "UTF-8";
* {
  font-family: "文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro","微軟正黑體","Microsoft JhengHei",DFKai-SB,sans-serif;
}

.glyphicon {
  font-family: "Glyphicons Halflings","文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro","微軟正黑體","Microsoft JhengHei",DFKai-SB,sans-serif;
}

.bg-image {
  background: url(../images/iclimb2019_bg.jpg) no-repeat top center/cover;
  width: 100%;
  height: 100%;
  transition: .5s;
}

.bg-image-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -9;
}

body {
  color: #5d5d5d;
  font-size: 16px;
}

.nolink {
  padding: 14px;
  display: block;
}

@media screen and (max-width: 768px) {
  .nolink {
    padding: 9px 15px;
  }
}

#block-hybridauth-hybridauth {
  position: absolute;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  right: 10px;
  top: 0;
  z-index: 999;
  opacity: 0.2;
  zoom: 0.7;
  overflow: hidden;
}

#block-hybridauth-hybridauth h2 {
  display: inline-block;
  vertical-align: sub;
}

#block-hybridauth-hybridauth .content {
  display: inline-block;
  vertical-align: middle;
}

#block-hybridauth-hybridauth:hover {
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  #block-hybridauth-hybridauth {
    zoom: 1;
  }
  #block-hybridauth-hybridauth h2 {
    display: none;
  }
}

.hybridauth-widget-wrapper {
  display: inline-block;
}

.not-logged-in #header-top {
  display: none;
}

div.messages, div.messages.status {
  /* Important messages (status, warning, and error) for the user. See also the declarations in messages.css. */
  margin: 1.5em 0;
  /* Drupal core uses "6px 0" margin */
  padding: 3px 3px 3px 59px;
  color: #3f3f3f;
  border: none;
  background: #75d675 url(../images/icon-tick.png) no-repeat 23px center;
}

div.messages ul, div.messages.status ul {
  margin-top: 0;
  margin-bottom: 0;
}

div.warning, div.messages.warning,
tr.warning {
  /* Medium priority messages */
  border-color: #e9c75f;
}

div.error, div.messages.error,
tr.error {
  /* High priority messages. See also the .error declaration below. */
  border-color: #e56464;
}

.error {
  /* Errors that are separate from div.messages status messages. */
  color: #3f3f3f;
}

div.messages.warning, div.warning {
  background: #e9c75f url(../images/icon-warning.png) no-repeat 23px center;
}

div.messages.error, div.error {
  background: #e56464 url(../images/icon-cross.png) no-repeat 23px center;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  div.messages.status, div.messages {
    background-image: url(../images/icon-tick-2x.png);
    background-size: 16px 16px;
  }
  div.messages.warning, div.warning {
    background-image: url(../images/icon-warning-2x.png);
    background-size: 16px 16px;
  }
  div.messages.error, div.error {
    background-image: url(../images/icon-cross-2x.png);
    background-size: 16px 16px;
  }
}

.messages-inner {
  padding: 15px 20px;
  background: #fff;
}

div.messages.status, div.status,
.ok {
  color: #3f3f3f;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus,
input.form-text:focus,
textarea.form-textarea:focus {
  border-color: #2196F3;
}

#logo img {
  height: 100px;
  margin: -10px 0 -15px;
}

#logo img.header--logo {
  height: 165px;
}

#logo img.print-only {
  display: none;
}

#header {
  padding: 10px 0;
  background: transparent;
}

#header.sticky {
  padding-bottom: 60px;
}

.auto-refresh, .print-only {
  display: none;
}

#main-navigation {
  background: transparent;
}

@media screen and (min-width: 768px) {
  #main-navigation.sticky {
    box-shadow: none;
  }
}

#main-navigation .navbar-header > .container {
  background: rgba(255, 183, 183, 0.95);
  border-radius: 10px;
}

#main-navigation .navbar-header .nav li a {
  letter-spacing: 0.5px;
}

#main-navigation .navbar-header .nav li.active-trail a, #main-navigation .navbar-header .nav li.active a {
  background: #ff6363;
}

#main-navigation .navbar-header .nav li > a:hover {
  background: #ff693a;
}

#main-navigation .navbar-header .nav li.dropdown:hover {
  background: #b38c53;
}

#main-navigation .navbar-header .nav li.dropdown.open > a {
  background: #b38c53;
}

#main-navigation .navbar-header .nav li.dropdown.open .dropdown-menu {
  background: #b38c53;
  border-bottom-color: #795548;
}

#main-navigation .navbar-header .nav li.dropdown.open .dropdown-menu a {
  border-bottom-color: #795548;
}

option[disabled="disabled"] {
  color: #d5d5d5;
}

.hide {
  display: none;
}

.remove-padding {
  padding: 0;
}

a:hover {
  text-decoration: none;
}

body .navbar-header .navbar-toggle {
  background: #b92200;
}

.navbar-header .nav li a, .navbar-header .nav li .nolink {
  color: #1f1f1f;
}

#breadcrumb {
  padding: 5px 0;
  background: rgba(240, 245, 247, 0);
}

#breadcrumb-inside > .container {
  background: rgba(240, 245, 247, 0.9);
  border-radius: 5px;
}

#breadcrumb .breadcrumb {
  margin: 5px 0;
}

#block-jquery-countdown-timer-jquery-countdown-timer {
  padding: 0;
}

#block-jquery-countdown-timer-jquery-countdown-timer h2 {
  color: #ff8300;
  text-align: center;
  margin: 0 0 5px;
  font-size: 28px;
}

#block-jquery-countdown-timer-jquery-countdown-timer #jquery-countdown-timer-note, #block-jquery-countdown-timer-jquery-countdown-timer .countWeeks, #block-jquery-countdown-timer-jquery-countdown-timer .countDiv0 {
  display: none;
}

#block-jquery-countdown-timer-jquery-countdown-timer #jquery-countdown-timer {
  width: 100%;
  height: 43px;
  overflow: hidden;
}

#main-content {
  padding: 0 0 20px;
  background: rgba(240, 245, 247, 0);
}

#main-content > .container {
  background: rgba(240, 245, 247, 0.9);
  -moz-box-shadow: 0 10px 20px 1px #ff6363;
  -webkit-box-shadow: 0 10px 20px 1px #ff6363;
  box-shadow: 0 10px 20px 1px #ff6363;
  border-radius: 5px;
}

h1.page-title {
  margin: 10px 0;
}

tbody tr td {
  vertical-align: middle;
}

tr.even {
  background-color: #DADADA !important;
  border-bottom: 2px solid #F5F5F5;
}

tr.even td {
  background-color: #DADADA !important;
  border-bottom: 2px solid #F5F5F5;
}

tr.odd {
  background-color: #F5F5F5;
  border-bottom: 2px solid #CCC;
}

tr.odd td {
  background-color: #F5F5F5;
  border-bottom: 2px solid #CCC;
}

/* Score Theme */
.gray-right {
  border-right: #A8A7A7 solid 2px;
}

.gray-bottom-imp {
  border-bottom: #A8A7A7 solid 2px !important;
}

.white-bottom {
  border-bottom: 5px solid #cccccc;
}

.views-row-last .white-bottom-last, .force-last.white-bottom-last {
  border-bottom: solid 5px #cccccc;
}

.white-left {
  border-left: 5px solid #cccccc;
}

.white-right {
  border-right: 5px solid #cccccc;
}

.white-top {
  border-top: 5px solid #cccccc;
}

.grays-bottom {
  border-bottom: 5px solid #777777;
}

.views-row-last .grays-bottom-last, .force-last.grays-bottom-last {
  border-bottom: solid 5px #777777;
}

.grays-left {
  border-left: 5px solid #777777;
}

.grays-right {
  border-right: 5px solid #777777;
}

.grays-top {
  border-top: 5px solid #777777;
}

.purple-top {
  border-top: #7D87F7 solid 5px;
}

.purple-bottom {
  border-bottom: #7D87F7 solid 5px;
}

.views-row-last .purple-bottom-last, .force-last.purple-bottom-last {
  border-bottom: #7D87F7 solid 5px;
}

.purple-right {
  border-right: #7D87F7 solid 5px;
}

.purple-left {
  border-left: #7D87F7 solid 5px;
}

.blue-bottom {
  border-bottom: 5px solid #10b3e2;
}

.views-row-last .blue-bottom-last, .force-last.blue-bottom-last {
  border-bottom: solid 5px #10b3e2;
}

.blue-left {
  border-left: 5px solid #10b3e2;
}

.blue-right {
  border-right: 5px solid #10b3e2;
}

.blue-top {
  border-top: 5px solid #10b3e2;
}

.green-top {
  border-top: #45CE92 solid 5px;
}

.green-bottom {
  border-bottom: #45CE92 solid 5px;
}

.views-row-last .green-bottom-last, .force-last.green-bottom-last {
  border-bottom: #45CE92 solid 5px;
}

.green-right {
  border-right: #45CE92 solid 5px;
}

.green-left {
  border-left: #45CE92 solid 5px;
}

.orange-top {
  border-top: #F8BA37 solid 5px;
}

.orange-bottom {
  border-bottom: #F8BA37 solid 5px;
}

.views-row-last .orange-bottom-last, .force-last.orange-bottom-last {
  border-bottom: #F8BA37 solid 5px;
}

.orange-right {
  border-right: #F8BA37 solid 5px;
}

.orange-left {
  border-left: #F8BA37 solid 5px;
}

.pink-top {
  border-top: #FF879F solid 5px;
}

.pink-bottom {
  border-bottom: #FF879F solid 5px;
}

.views-row-last .pink-bottom-last, .force-last.pink-bottom-last {
  border-bottom: #FF879F solid 5px;
}

.pink-right {
  border-right: #FF879F solid 5px;
}

.pink-left {
  border-left: #FF879F solid 5px;
}

.red-top {
  border-top: #F00 solid 5px;
}

.red-bottom {
  border-bottom: #F00 solid 5px;
}

.views-row-last .red-bottom-last, .force-last.red-bottom-last {
  border-bottom: #F00 solid 5px;
}

.red-right {
  border-right: #F00 solid 5px;
}

.red-left {
  border-left: #F00 solid 5px;
}

.path-event .content .field-name-body p {
  font-size: 1.2em;
  letter-spacing: 1px;
  line-height: 1.5;
}

.page-event-result table {
  width: 100%;
}

@media screen and (max-width: 550px) {
  .page-event-result table {
    width: auto;
    position: relative;
    white-space: nowrap;
    display: block;
    overflow-x: auto;
  }
  .page-event-result .views-field-php {
    min-width: 150px;
  }
}

.page-event-result th {
  background-color: #FFF;
  border-bottom: 3px solid #ccc;
}

.page-event-result .view-event-result .view-header h2 {
  font-size: 2em;
  text-align: center;
}

.page-event-result #paying-form {
  padding: 0 20px 20px;
  border: #54B4D2 5px solid;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.4);
}

.page-event-result .pay-status {
  padding: 15px;
  min-width: 180px;
}

.page-event-result .pay-status .text {
  font-size: 1.2em;
  font-weight: 700;
  color: white;
}

.page-event-result .pay-status .text img {
  display: inline;
}

.page-event-result .pay-status p {
  margin: 0 !important;
}

.page-event-result .pay-status.nopay {
  background: #e56464;
}

.page-event-result .pay-status.nopay .text img.paying_edit {
  padding-left: 10px;
  cursor: pointer;
}

.page-event-result .pay-status.nopay .text img.paying_at_check {
  cursor: pointer;
  background-color: #C5EAA3;
  padding: 5px;
  border-radius: 10px;
  width: 30px;
  height: 30px;
}

.page-event-result .pay-status.checking {
  background: #e9c75f;
}

.page-event-result .pay-status.checking img.paying_edit {
  padding-left: 10px;
  cursor: pointer;
}

.page-event-result .pay-status.checking img.paying_check {
  cursor: pointer;
  background-color: #C5EAA3;
  padding: 5px;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  margin-bottom: -2px;
}

.page-event-result .pay-status.checking .payinfo {
  background: rgba(255, 255, 255, 0.8);
}

.page-event-result .pay-status.payed {
  background: #75d675;
}

.page-event-result .pay-status.payed .payinfo {
  background: rgba(255, 255, 255, 0.8);
}

.page-event-signup table {
  width: 100%;
}

@media screen and (max-width: 740px) {
  .page-event-signup .views-field-php {
    min-width: 150px;
  }
}

.page-event-signup th {
  background-color: #FFF;
  border-bottom: 3px solid #ccc;
}

.page-event-signup th.views-field-field-rfid-editable {
  display: none;
}

.page-event-signup th.views-field-field-final-rfid-editable {
  display: none;
}

.page-event-signup .view-event-signup .view-header h2, .page-event-signup .view-event-final-signup .view-header h2 {
  font-size: 2em;
  text-align: center;
}

.page-event-signup .signup-status {
  padding: 15px;
}

.page-event-signup .signup-status .text {
  position: relative;
  left: -15px;
  font-size: 1.2em;
  font-weight: 700;
  color: white;
}

.page-event-signup .signup-status .text img {
  display: inline;
}

.page-event-signup .signup-status .text input[type="text"] {
  width: auto;
}

.page-event-signup .signup-status p {
  margin: 0px;
}

.page-event-signup .signup-status.unsign {
  background: #e56464;
}

.page-event-signup .signup-status.unsign .text img.signup_edit {
  padding-left: 10px;
  position: absolute;
  top: -3px;
  cursor: pointer;
}

.page-event-signup .signup-status.signed {
  background: #75d675;
}

.path-time tr td:first-child {
  text-align: center;
  border-right: #C7C7C7 solid 5px;
}

.path-time table {
  font-size: 19px;
  border-color: transparent;
}

.path-time tr:nth-child(2n) {
  background-color: #dadada !important;
  border-bottom: 2px solid #f5f5f5;
}

.path-time tr:nth-child(n+1) {
  background-color: #f5f5f5;
  border-bottom: 2px solid #CCC;
}

@media screen and (max-width: 767px) {
  .path-time table {
    width: 100% !important;
  }
}

.page-qua-list #header {
  display: none;
}

.page-qua-list #main-navigation {
  background-image: url(/sites/all/themes/bootpress/images/iclimb2019_logo.png), url(/sites/all/themes/bootpress/images/iclimb2019_title.png);
  background-repeat: no-repeat;
  background-size: auto 50px, auto 40px;
  background-position: 5px 0, 50px 4px;
}

.page-qua-list #main-navigation.sticky {
  position: relative;
}

.page-qua-list #secondary-menu {
  display: none;
}

.page-qua-list .breadcrumb, .page-qua-list #header-top {
  display: none;
}

.page-qua-list #page {
  overflow: hidden;
}

.page-qua-list #page-title {
  margin: 0;
}

.page-qua-list #content {
  padding: 0 10px;
}

.page-qua-list input.qua_add_text {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.page-qua-list .view-display-id-qua_list table, .page-qua-list .view-display-id-qua_list_2 table {
  width: 100%;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 700;
}

.page-qua-list .view-display-id-qua_list table thead, .page-qua-list .view-display-id-qua_list_2 table thead {
  font-size: 10px;
}

.page-qua-list .view-display-id-qua_list table thead th, .page-qua-list .view-display-id-qua_list_2 table thead th {
  padding: 5px;
}

.page-qua-list .view-display-id-qua_list table tbody .views-field-title, .page-qua-list .view-display-id-qua_list_2 table tbody .views-field-title {
  padding: 0 5px;
  max-width: 100px;
}

.page-qua-list .view-display-id-qua_list > .view-content, .page-qua-list .view-display-id-qua_list > .view-footer {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}

.page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(1), .page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(2), .page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(3) {
  font-size: 20px;
  border: #F00 solid 5px;
}

.page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(1) td, .page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(2) td, .page-qua-list .view-display-id-qua_list > .view-content tbody tr:nth-child(3) td {
  padding: 0px;
  height: 55px;
}

.page-qua-edit {
  font-size: 14px;
}

.page-qua-edit #header {
  display: none;
}

.page-qua-edit #main-navigation {
  background-image: url(/sites/all/themes/bootpress/images/iclimb2019_logo.png), url(/sites/all/themes/bootpress/images/iclimb2019_title.png);
  background-repeat: no-repeat;
  background-size: auto 42px,auto 46px;
  background-position: 10px 5px,75px 5px;
}

.page-qua-edit #secondary-menu {
  display: none;
}

.page-qua-edit .breadcrumb, .page-qua-edit #header-top {
  display: none;
}

.page-qua-edit #page-title {
  margin: 0;
  padding-top: 5px;
}

.page-qua-edit #content {
  padding: 0 20px 120px;
}

.page-qua-edit .return, .page-qua-edit .delete {
  padding-left: 10px;
  display: inline-block;
}

.page-qua-edit table {
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: 500;
  margin: 0px;
}

.page-qua-edit table tbody tr.header td {
  padding: 10px;
  width: 50%;
}

.page-qua-edit table tbody tr.status td {
  margin: 5px 5%;
  padding: 20px 10px;
}

.page-qua-edit table tbody tr.status td .btn {
  padding: 50px 0;
  font-size: 30px;
}

.page-qua-edit table tbody tr.status td .btn:hover, .page-qua-edit table tbody tr.status td .btn:active, .page-qua-edit table tbody tr.status td .btn:focus {
  background-position: 0;
  border-color: #BBB;
}

.page-qua-edit table tbody tr.status td .btn[done="0"] {
  background-color: #FFF;
  background-image: linear-gradient(to bottom, #BBB, #BBB);
  color: #777;
  border-width: 5px;
}

.page-qua-edit table tbody tr.status td .btn[done="0"]:before {
  content: "-";
  color: #444;
  font-size: 30px;
  line-height: 0.5;
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
  left: 0;
}

.page-qua-edit table tbody tr.status td .btn[done="1"] {
  border-width: 5px;
}

.page-qua-edit table tbody tr.status td .btn[done="1"]:before {
  content: "✔";
  color: #9dffa2;
  font-size: 30px;
  line-height: 0.5;
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
  left: 0;
}

.page-qua-edit .view-display-id-qua_edit_next_2 {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 2000;
}

.page-qua-edit .view-display-id-qua_edit_next_2 .views-row {
  display: inline-block;
  width: 49%;
  text-align: center;
  padding: 5px;
  background: #ffac33;
  margin: auto;
  color: #FFF;
  position: relative;
}

.page-qua-edit .view-display-id-qua_edit_next_2 .views-row .views-field-counter {
  text-align: left;
  font-size: 2.5em;
  font-weight: 700;
  padding: 0 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-qua-edit .view-display-id-qua_edit_next_2 .views-row .views-field-field-no {
  text-align: left;
  font-size: 1.2em;
  padding-left: 25px;
  vertical-align: top;
}

.page-qua-edit .view-display-id-qua_edit_next_2 .views-row .views-field-title {
  display: inline-block;
  width: 80%;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 20px;
  max-height: 40px;
  min-height: 30px;
  vertical-align: bottom;
}

.page-qua-check .qua-result .views-field {
  min-width: 100px;
  min-height: 100px;
  display: inline-block;
  position: relative;
  padding: 20px;
  font-size: 2.5em;
  font-weight: 700;
  line-height: 1.5em;
  margin: 5px;
  border-radius: 10px;
  text-align: center;
  background: #f7d3a4;
  vertical-align: top;
}

.page-qua-check .qua-result .views-field.status-none {
  font-size: 1px;
  color: transparent;
}

.page-qua-check .qua-result .views-field.qua-result {
  background: #795548;
  padding: 20px 10px;
  color: #FFF;
  min-width: 115px;
}

.page-qua-check .qua-result .views-field.qua-result:before {
  content: attr(text);
  font-size: 25px;
  color: #ffc107;
  font-weight: 900;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 1;
}

.page-qua-check .qua-result .views-field.qua-route:before {
  content: attr(route);
  font-size: 25px;
  color: #FF5722;
  font-weight: 900;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 1;
}

.page-qua-check #header-top {
  display: none;
}

.page-qua-check #edit-field-rfid-value-wrapper label {
  display: none;
}

.page-qua-check #edit-field-rfid-value-wrapper input[type="text"] {
  max-width: 150px;
}

.page-qua-check .views-exposed-form .views-exposed-widget .form-submit {
  margin: 0;
}

.page-qua-full .view-display-id-qua_list_full_1 > .view-header > .view {
  display: inline-block;
  width: 22%;
  margin: 0 1%;
}

.page-qua-full .view-display-id-qua_list_full_1 > .view-header > .view table {
  width: 100%;
}

.page-qua-full .views-field-title {
  max-width: 125px;
  padding: 0px 5px;
}

@media screen and (max-width: 1024px) {
  .page-qua-full .view-display-id-qua_list_full_1 > .view-header > .view {
    display: block;
    width: 100%;
    margin: 0 1%;
  }
}

.page-score #breadcrumb, .page-player-edit #breadcrumb {
  display: none;
}

.path-score-qua-edit, .path-score-semi-edit, .path-score-final-edit,
.path-score-qua-rank, .path-score-semi-rank, .path-score-final-rank,
.path-score-final-none, .path-score-final-men-inter, .path-score-final-women-inter, .path-score-final-men-open, .path-score-final-women-open,
.path-score-semi-none, .path-score-semi-men-inter, .path-score-semi-women-inter, .path-score-semi-men-open, .path-score-semi-women-open,
.path-score-qua-none, .path-score-qua-men-inter, .path-score-qua-women-inter, .path-score-qua-men-open, .path-score-qua-women-open,
.path-score-all-none, .path-score-all-men-inter, .path-score-all-women-inter, .path-score-all-men-open, .path-score-all-women-open {
  font-size: 1.2em;
}

.path-score-qua-edit .nav-tabs, .path-score-semi-edit .nav-tabs, .path-score-final-edit .nav-tabs,
.path-score-qua-rank .nav-tabs, .path-score-semi-rank .nav-tabs, .path-score-final-rank .nav-tabs,
.path-score-final-none .nav-tabs, .path-score-final-men-inter .nav-tabs, .path-score-final-women-inter .nav-tabs, .path-score-final-men-open .nav-tabs, .path-score-final-women-open .nav-tabs,
.path-score-semi-none .nav-tabs, .path-score-semi-men-inter .nav-tabs, .path-score-semi-women-inter .nav-tabs, .path-score-semi-men-open .nav-tabs, .path-score-semi-women-open .nav-tabs,
.path-score-qua-none .nav-tabs, .path-score-qua-men-inter .nav-tabs, .path-score-qua-women-inter .nav-tabs, .path-score-qua-men-open .nav-tabs, .path-score-qua-women-open .nav-tabs,
.path-score-all-none .nav-tabs, .path-score-all-men-inter .nav-tabs, .path-score-all-women-inter .nav-tabs, .path-score-all-men-open .nav-tabs, .path-score-all-women-open .nav-tabs {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;
  list-style: none;
  -ms-box-orient: horizontal;
  -ms-box-pack: center;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .path-score-qua-edit .nav-tabs, .path-score-semi-edit .nav-tabs, .path-score-final-edit .nav-tabs,
  .path-score-qua-rank .nav-tabs, .path-score-semi-rank .nav-tabs, .path-score-final-rank .nav-tabs,
  .path-score-final-none .nav-tabs, .path-score-final-men-inter .nav-tabs, .path-score-final-women-inter .nav-tabs, .path-score-final-men-open .nav-tabs, .path-score-final-women-open .nav-tabs,
  .path-score-semi-none .nav-tabs, .path-score-semi-men-inter .nav-tabs, .path-score-semi-women-inter .nav-tabs, .path-score-semi-men-open .nav-tabs, .path-score-semi-women-open .nav-tabs,
  .path-score-qua-none .nav-tabs, .path-score-qua-men-inter .nav-tabs, .path-score-qua-women-inter .nav-tabs, .path-score-qua-men-open .nav-tabs, .path-score-qua-women-open .nav-tabs,
  .path-score-all-none .nav-tabs, .path-score-all-men-inter .nav-tabs, .path-score-all-women-inter .nav-tabs, .path-score-all-men-open .nav-tabs, .path-score-all-women-open .nav-tabs {
    -webkit-justify-content: inherit;
    -moz-justify-content: inherit;
    -ms-justify-content: inherit;
    justify-content: inherit;
  }
}

.path-score-qua-edit .nav-tabs li, .path-score-semi-edit .nav-tabs li, .path-score-final-edit .nav-tabs li,
.path-score-qua-rank .nav-tabs li, .path-score-semi-rank .nav-tabs li, .path-score-final-rank .nav-tabs li,
.path-score-final-none .nav-tabs li, .path-score-final-men-inter .nav-tabs li, .path-score-final-women-inter .nav-tabs li, .path-score-final-men-open .nav-tabs li, .path-score-final-women-open .nav-tabs li,
.path-score-semi-none .nav-tabs li, .path-score-semi-men-inter .nav-tabs li, .path-score-semi-women-inter .nav-tabs li, .path-score-semi-men-open .nav-tabs li, .path-score-semi-women-open .nav-tabs li,
.path-score-qua-none .nav-tabs li, .path-score-qua-men-inter .nav-tabs li, .path-score-qua-women-inter .nav-tabs li, .path-score-qua-men-open .nav-tabs li, .path-score-qua-women-open .nav-tabs li,
.path-score-all-none .nav-tabs li, .path-score-all-men-inter .nav-tabs li, .path-score-all-women-inter .nav-tabs li, .path-score-all-men-open .nav-tabs li, .path-score-all-women-open .nav-tabs li {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0;
  text-align: center;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media screen and (max-width: 600px) {
  .path-score-qua-edit .nav-tabs li, .path-score-semi-edit .nav-tabs li, .path-score-final-edit .nav-tabs li,
  .path-score-qua-rank .nav-tabs li, .path-score-semi-rank .nav-tabs li, .path-score-final-rank .nav-tabs li,
  .path-score-final-none .nav-tabs li, .path-score-final-men-inter .nav-tabs li, .path-score-final-women-inter .nav-tabs li, .path-score-final-men-open .nav-tabs li, .path-score-final-women-open .nav-tabs li,
  .path-score-semi-none .nav-tabs li, .path-score-semi-men-inter .nav-tabs li, .path-score-semi-women-inter .nav-tabs li, .path-score-semi-men-open .nav-tabs li, .path-score-semi-women-open .nav-tabs li,
  .path-score-qua-none .nav-tabs li, .path-score-qua-men-inter .nav-tabs li, .path-score-qua-women-inter .nav-tabs li, .path-score-qua-men-open .nav-tabs li, .path-score-qua-women-open .nav-tabs li,
  .path-score-all-none .nav-tabs li, .path-score-all-men-inter .nav-tabs li, .path-score-all-women-inter .nav-tabs li, .path-score-all-men-open .nav-tabs li, .path-score-all-women-open .nav-tabs li {
    -webkit-flex: inherit;
    -moz-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
  }
}

.path-score-qua-edit .nav-tabs li a, .path-score-semi-edit .nav-tabs li a, .path-score-final-edit .nav-tabs li a,
.path-score-qua-rank .nav-tabs li a, .path-score-semi-rank .nav-tabs li a, .path-score-final-rank .nav-tabs li a,
.path-score-final-none .nav-tabs li a, .path-score-final-men-inter .nav-tabs li a, .path-score-final-women-inter .nav-tabs li a, .path-score-final-men-open .nav-tabs li a, .path-score-final-women-open .nav-tabs li a,
.path-score-semi-none .nav-tabs li a, .path-score-semi-men-inter .nav-tabs li a, .path-score-semi-women-inter .nav-tabs li a, .path-score-semi-men-open .nav-tabs li a, .path-score-semi-women-open .nav-tabs li a,
.path-score-qua-none .nav-tabs li a, .path-score-qua-men-inter .nav-tabs li a, .path-score-qua-women-inter .nav-tabs li a, .path-score-qua-men-open .nav-tabs li a, .path-score-qua-women-open .nav-tabs li a,
.path-score-all-none .nav-tabs li a, .path-score-all-men-inter .nav-tabs li a, .path-score-all-women-inter .nav-tabs li a, .path-score-all-men-open .nav-tabs li a, .path-score-all-women-open .nav-tabs li a {
  padding: 0 1.5em 0 0.3em;
  color: #c7574e;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  line-height: 2.5;
  font-weight: 700;
  letter-spacing: 1px;
  opacity: 0.9;
}

.path-score-qua-edit .nav-tabs li a:after, .path-score-semi-edit .nav-tabs li a:after, .path-score-final-edit .nav-tabs li a:after,
.path-score-qua-rank .nav-tabs li a:after, .path-score-semi-rank .nav-tabs li a:after, .path-score-final-rank .nav-tabs li a:after,
.path-score-final-none .nav-tabs li a:after, .path-score-final-men-inter .nav-tabs li a:after, .path-score-final-women-inter .nav-tabs li a:after, .path-score-final-men-open .nav-tabs li a:after, .path-score-final-women-open .nav-tabs li a:after,
.path-score-semi-none .nav-tabs li a:after, .path-score-semi-men-inter .nav-tabs li a:after, .path-score-semi-women-inter .nav-tabs li a:after, .path-score-semi-men-open .nav-tabs li a:after, .path-score-semi-women-open .nav-tabs li a:after,
.path-score-qua-none .nav-tabs li a:after, .path-score-qua-men-inter .nav-tabs li a:after, .path-score-qua-women-inter .nav-tabs li a:after, .path-score-qua-men-open .nav-tabs li a:after, .path-score-qua-women-open .nav-tabs li a:after,
.path-score-all-none .nav-tabs li a:after, .path-score-all-men-inter .nav-tabs li a:after, .path-score-all-women-inter .nav-tabs li a:after, .path-score-all-men-open .nav-tabs li a:after, .path-score-all-women-open .nav-tabs li a:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid transparent;
  border-radius: 10px 10px 0 0;
  background: #ffafa9;
  box-shadow: inset 0 -3px 3px rgba(0, 0, 0, 0.05);
  content: '';
  -webkit-transform: perspective(5px) rotateX(0.93deg) translateZ(-0.8px);
  transform: perspective(5px) rotateX(0.93deg) translateZ(-0.8px);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.path-score-qua-edit .nav-tabs li a:hover, .path-score-qua-edit .nav-tabs li a:focus, .path-score-semi-edit .nav-tabs li a:hover, .path-score-semi-edit .nav-tabs li a:focus, .path-score-final-edit .nav-tabs li a:hover, .path-score-final-edit .nav-tabs li a:focus,
.path-score-qua-rank .nav-tabs li a:hover,
.path-score-qua-rank .nav-tabs li a:focus, .path-score-semi-rank .nav-tabs li a:hover, .path-score-semi-rank .nav-tabs li a:focus, .path-score-final-rank .nav-tabs li a:hover, .path-score-final-rank .nav-tabs li a:focus,
.path-score-final-none .nav-tabs li a:hover,
.path-score-final-none .nav-tabs li a:focus, .path-score-final-men-inter .nav-tabs li a:hover, .path-score-final-men-inter .nav-tabs li a:focus, .path-score-final-women-inter .nav-tabs li a:hover, .path-score-final-women-inter .nav-tabs li a:focus, .path-score-final-men-open .nav-tabs li a:hover, .path-score-final-men-open .nav-tabs li a:focus, .path-score-final-women-open .nav-tabs li a:hover, .path-score-final-women-open .nav-tabs li a:focus,
.path-score-semi-none .nav-tabs li a:hover,
.path-score-semi-none .nav-tabs li a:focus, .path-score-semi-men-inter .nav-tabs li a:hover, .path-score-semi-men-inter .nav-tabs li a:focus, .path-score-semi-women-inter .nav-tabs li a:hover, .path-score-semi-women-inter .nav-tabs li a:focus, .path-score-semi-men-open .nav-tabs li a:hover, .path-score-semi-men-open .nav-tabs li a:focus, .path-score-semi-women-open .nav-tabs li a:hover, .path-score-semi-women-open .nav-tabs li a:focus,
.path-score-qua-none .nav-tabs li a:hover,
.path-score-qua-none .nav-tabs li a:focus, .path-score-qua-men-inter .nav-tabs li a:hover, .path-score-qua-men-inter .nav-tabs li a:focus, .path-score-qua-women-inter .nav-tabs li a:hover, .path-score-qua-women-inter .nav-tabs li a:focus, .path-score-qua-men-open .nav-tabs li a:hover, .path-score-qua-men-open .nav-tabs li a:focus, .path-score-qua-women-open .nav-tabs li a:hover, .path-score-qua-women-open .nav-tabs li a:focus,
.path-score-all-none .nav-tabs li a:hover,
.path-score-all-none .nav-tabs li a:focus, .path-score-all-men-inter .nav-tabs li a:hover, .path-score-all-men-inter .nav-tabs li a:focus, .path-score-all-women-inter .nav-tabs li a:hover, .path-score-all-women-inter .nav-tabs li a:focus, .path-score-all-men-open .nav-tabs li a:hover, .path-score-all-men-open .nav-tabs li a:focus, .path-score-all-women-open .nav-tabs li a:hover, .path-score-all-women-open .nav-tabs li a:focus {
  background: inherit;
  color: #FFF;
  border: 1px transparent solid;
}

.path-score-qua-edit .nav-tabs li a.active, .path-score-semi-edit .nav-tabs li a.active, .path-score-final-edit .nav-tabs li a.active,
.path-score-qua-rank .nav-tabs li a.active, .path-score-semi-rank .nav-tabs li a.active, .path-score-final-rank .nav-tabs li a.active,
.path-score-final-none .nav-tabs li a.active, .path-score-final-men-inter .nav-tabs li a.active, .path-score-final-women-inter .nav-tabs li a.active, .path-score-final-men-open .nav-tabs li a.active, .path-score-final-women-open .nav-tabs li a.active,
.path-score-semi-none .nav-tabs li a.active, .path-score-semi-men-inter .nav-tabs li a.active, .path-score-semi-women-inter .nav-tabs li a.active, .path-score-semi-men-open .nav-tabs li a.active, .path-score-semi-women-open .nav-tabs li a.active,
.path-score-qua-none .nav-tabs li a.active, .path-score-qua-men-inter .nav-tabs li a.active, .path-score-qua-women-inter .nav-tabs li a.active, .path-score-qua-men-open .nav-tabs li a.active, .path-score-qua-women-open .nav-tabs li a.active,
.path-score-all-none .nav-tabs li a.active, .path-score-all-men-inter .nav-tabs li a.active, .path-score-all-women-inter .nav-tabs li a.active, .path-score-all-men-open .nav-tabs li a.active, .path-score-all-women-open .nav-tabs li a.active {
  color: #ec6459;
}

.path-score-qua-edit .nav-tabs li a.active:after, .path-score-semi-edit .nav-tabs li a.active:after, .path-score-final-edit .nav-tabs li a.active:after,
.path-score-qua-rank .nav-tabs li a.active:after, .path-score-semi-rank .nav-tabs li a.active:after, .path-score-final-rank .nav-tabs li a.active:after,
.path-score-final-none .nav-tabs li a.active:after, .path-score-final-men-inter .nav-tabs li a.active:after, .path-score-final-women-inter .nav-tabs li a.active:after, .path-score-final-men-open .nav-tabs li a.active:after, .path-score-final-women-open .nav-tabs li a.active:after,
.path-score-semi-none .nav-tabs li a.active:after, .path-score-semi-men-inter .nav-tabs li a.active:after, .path-score-semi-women-inter .nav-tabs li a.active:after, .path-score-semi-men-open .nav-tabs li a.active:after, .path-score-semi-women-open .nav-tabs li a.active:after,
.path-score-qua-none .nav-tabs li a.active:after, .path-score-qua-men-inter .nav-tabs li a.active:after, .path-score-qua-women-inter .nav-tabs li a.active:after, .path-score-qua-men-open .nav-tabs li a.active:after, .path-score-qua-women-open .nav-tabs li a.active:after,
.path-score-all-none .nav-tabs li a.active:after, .path-score-all-men-inter .nav-tabs li a.active:after, .path-score-all-women-inter .nav-tabs li a.active:after, .path-score-all-men-open .nav-tabs li a.active:after, .path-score-all-women-open .nav-tabs li a.active:after {
  background: #fff;
  box-shadow: none;
}

.path-score-qua-edit table, .path-score-semi-edit table, .path-score-final-edit table,
.path-score-qua-rank table, .path-score-semi-rank table, .path-score-final-rank table,
.path-score-final-none table, .path-score-final-men-inter table, .path-score-final-women-inter table, .path-score-final-men-open table, .path-score-final-women-open table,
.path-score-semi-none table, .path-score-semi-men-inter table, .path-score-semi-women-inter table, .path-score-semi-men-open table, .path-score-semi-women-open table,
.path-score-qua-none table, .path-score-qua-men-inter table, .path-score-qua-women-inter table, .path-score-qua-men-open table, .path-score-qua-women-open table,
.path-score-all-none table, .path-score-all-men-inter table, .path-score-all-women-inter table, .path-score-all-men-open table, .path-score-all-women-open table {
  width: 100%;
  text-align: center;
  border: none;
}

.path-score-qua-edit thead, .path-score-semi-edit thead, .path-score-final-edit thead,
.path-score-qua-rank thead, .path-score-semi-rank thead, .path-score-final-rank thead,
.path-score-final-none thead, .path-score-final-men-inter thead, .path-score-final-women-inter thead, .path-score-final-men-open thead, .path-score-final-women-open thead,
.path-score-semi-none thead, .path-score-semi-men-inter thead, .path-score-semi-women-inter thead, .path-score-semi-men-open thead, .path-score-semi-women-open thead,
.path-score-qua-none thead, .path-score-qua-men-inter thead, .path-score-qua-women-inter thead, .path-score-qua-men-open thead, .path-score-qua-women-open thead,
.path-score-all-none thead, .path-score-all-men-inter thead, .path-score-all-women-inter thead, .path-score-all-men-open thead, .path-score-all-women-open thead {
  border-bottom: 5px solid #CCC;
}

.path-score-qua-edit thead th, .path-score-semi-edit thead th, .path-score-final-edit thead th,
.path-score-qua-rank thead th, .path-score-semi-rank thead th, .path-score-final-rank thead th,
.path-score-final-none thead th, .path-score-final-men-inter thead th, .path-score-final-women-inter thead th, .path-score-final-men-open thead th, .path-score-final-women-open thead th,
.path-score-semi-none thead th, .path-score-semi-men-inter thead th, .path-score-semi-women-inter thead th, .path-score-semi-men-open thead th, .path-score-semi-women-open thead th,
.path-score-qua-none thead th, .path-score-qua-men-inter thead th, .path-score-qua-women-inter thead th, .path-score-qua-men-open thead th, .path-score-qua-women-open thead th,
.path-score-all-none thead th, .path-score-all-men-inter thead th, .path-score-all-women-inter thead th, .path-score-all-men-open thead th, .path-score-all-women-open thead th {
  border-bottom: 0;
  background: #FFF;
  vertical-align: middle;
}

.path-score-qua-edit thead th.empty, .path-score-semi-edit thead th.empty, .path-score-final-edit thead th.empty,
.path-score-qua-rank thead th.empty, .path-score-semi-rank thead th.empty, .path-score-final-rank thead th.empty,
.path-score-final-none thead th.empty, .path-score-final-men-inter thead th.empty, .path-score-final-women-inter thead th.empty, .path-score-final-men-open thead th.empty, .path-score-final-women-open thead th.empty,
.path-score-semi-none thead th.empty, .path-score-semi-men-inter thead th.empty, .path-score-semi-women-inter thead th.empty, .path-score-semi-men-open thead th.empty, .path-score-semi-women-open thead th.empty,
.path-score-qua-none thead th.empty, .path-score-qua-men-inter thead th.empty, .path-score-qua-women-inter thead th.empty, .path-score-qua-men-open thead th.empty, .path-score-qua-women-open thead th.empty,
.path-score-all-none thead th.empty, .path-score-all-men-inter thead th.empty, .path-score-all-women-inter thead th.empty, .path-score-all-men-open thead th.empty, .path-score-all-women-open thead th.empty {
  background: transparent;
  border: none;
}

.path-score-qua-edit input[type="text"], .path-score-semi-edit input[type="text"], .path-score-final-edit input[type="text"],
.path-score-qua-rank input[type="text"], .path-score-semi-rank input[type="text"], .path-score-final-rank input[type="text"],
.path-score-final-none input[type="text"], .path-score-final-men-inter input[type="text"], .path-score-final-women-inter input[type="text"], .path-score-final-men-open input[type="text"], .path-score-final-women-open input[type="text"],
.path-score-semi-none input[type="text"], .path-score-semi-men-inter input[type="text"], .path-score-semi-women-inter input[type="text"], .path-score-semi-men-open input[type="text"], .path-score-semi-women-open input[type="text"],
.path-score-qua-none input[type="text"], .path-score-qua-men-inter input[type="text"], .path-score-qua-women-inter input[type="text"], .path-score-qua-men-open input[type="text"], .path-score-qua-women-open input[type="text"],
.path-score-all-none input[type="text"], .path-score-all-men-inter input[type="text"], .path-score-all-women-inter input[type="text"], .path-score-all-men-open input[type="text"], .path-score-all-women-open input[type="text"] {
  width: 35px;
  text-align: center;
  padding: 0;
  min-height: inherit;
}

.path-score-qua-edit label.option, .path-score-semi-edit label.option, .path-score-final-edit label.option,
.path-score-qua-rank label.option, .path-score-semi-rank label.option, .path-score-final-rank label.option,
.path-score-final-none label.option, .path-score-final-men-inter label.option, .path-score-final-women-inter label.option, .path-score-final-men-open label.option, .path-score-final-women-open label.option,
.path-score-semi-none label.option, .path-score-semi-men-inter label.option, .path-score-semi-women-inter label.option, .path-score-semi-men-open label.option, .path-score-semi-women-open label.option,
.path-score-qua-none label.option, .path-score-qua-men-inter label.option, .path-score-qua-women-inter label.option, .path-score-qua-men-open label.option, .path-score-qua-women-open label.option,
.path-score-all-none label.option, .path-score-all-men-inter label.option, .path-score-all-women-inter label.option, .path-score-all-men-open label.option, .path-score-all-women-open label.option {
  display: none;
}

.path-score-qua-edit input[type="checkbox"], .path-score-semi-edit input[type="checkbox"], .path-score-final-edit input[type="checkbox"],
.path-score-qua-rank input[type="checkbox"], .path-score-semi-rank input[type="checkbox"], .path-score-final-rank input[type="checkbox"],
.path-score-final-none input[type="checkbox"], .path-score-final-men-inter input[type="checkbox"], .path-score-final-women-inter input[type="checkbox"], .path-score-final-men-open input[type="checkbox"], .path-score-final-women-open input[type="checkbox"],
.path-score-semi-none input[type="checkbox"], .path-score-semi-men-inter input[type="checkbox"], .path-score-semi-women-inter input[type="checkbox"], .path-score-semi-men-open input[type="checkbox"], .path-score-semi-women-open input[type="checkbox"],
.path-score-qua-none input[type="checkbox"], .path-score-qua-men-inter input[type="checkbox"], .path-score-qua-women-inter input[type="checkbox"], .path-score-qua-men-open input[type="checkbox"], .path-score-qua-women-open input[type="checkbox"],
.path-score-all-none input[type="checkbox"], .path-score-all-men-inter input[type="checkbox"], .path-score-all-women-inter input[type="checkbox"], .path-score-all-men-open input[type="checkbox"], .path-score-all-women-open input[type="checkbox"] {
  width: 30px;
  height: 30px;
  min-height: inherit;
}

.path-score-qua-edit th, .path-score-qua-edit td, .path-score-semi-edit th, .path-score-semi-edit td, .path-score-final-edit th, .path-score-final-edit td,
.path-score-qua-rank th,
.path-score-qua-rank td, .path-score-semi-rank th, .path-score-semi-rank td, .path-score-final-rank th, .path-score-final-rank td,
.path-score-final-none th,
.path-score-final-none td, .path-score-final-men-inter th, .path-score-final-men-inter td, .path-score-final-women-inter th, .path-score-final-women-inter td, .path-score-final-men-open th, .path-score-final-men-open td, .path-score-final-women-open th, .path-score-final-women-open td,
.path-score-semi-none th,
.path-score-semi-none td, .path-score-semi-men-inter th, .path-score-semi-men-inter td, .path-score-semi-women-inter th, .path-score-semi-women-inter td, .path-score-semi-men-open th, .path-score-semi-men-open td, .path-score-semi-women-open th, .path-score-semi-women-open td,
.path-score-qua-none th,
.path-score-qua-none td, .path-score-qua-men-inter th, .path-score-qua-men-inter td, .path-score-qua-women-inter th, .path-score-qua-women-inter td, .path-score-qua-men-open th, .path-score-qua-men-open td, .path-score-qua-women-open th, .path-score-qua-women-open td,
.path-score-all-none th,
.path-score-all-none td, .path-score-all-men-inter th, .path-score-all-men-inter td, .path-score-all-women-inter th, .path-score-all-women-inter td, .path-score-all-men-open th, .path-score-all-men-open td, .path-score-all-women-open th, .path-score-all-women-open td {
  padding: 5px;
  text-align: center !important;
}

.path-score-qua-edit .views-row-edit-static, .path-score-semi-edit .views-row-edit-static, .path-score-final-edit .views-row-edit-static,
.path-score-qua-rank .views-row-edit-static, .path-score-semi-rank .views-row-edit-static, .path-score-final-rank .views-row-edit-static,
.path-score-final-none .views-row-edit-static, .path-score-final-men-inter .views-row-edit-static, .path-score-final-women-inter .views-row-edit-static, .path-score-final-men-open .views-row-edit-static, .path-score-final-women-open .views-row-edit-static,
.path-score-semi-none .views-row-edit-static, .path-score-semi-men-inter .views-row-edit-static, .path-score-semi-women-inter .views-row-edit-static, .path-score-semi-men-open .views-row-edit-static, .path-score-semi-women-open .views-row-edit-static,
.path-score-qua-none .views-row-edit-static, .path-score-qua-men-inter .views-row-edit-static, .path-score-qua-women-inter .views-row-edit-static, .path-score-qua-men-open .views-row-edit-static, .path-score-qua-women-open .views-row-edit-static,
.path-score-all-none .views-row-edit-static, .path-score-all-men-inter .views-row-edit-static, .path-score-all-women-inter .views-row-edit-static, .path-score-all-men-open .views-row-edit-static, .path-score-all-women-open .views-row-edit-static {
  display: none;
}

.path-score-qua-edit .views-row-edit-edit label, .path-score-semi-edit .views-row-edit-edit label, .path-score-final-edit .views-row-edit-edit label,
.path-score-qua-rank .views-row-edit-edit label, .path-score-semi-rank .views-row-edit-edit label, .path-score-final-rank .views-row-edit-edit label,
.path-score-final-none .views-row-edit-edit label, .path-score-final-men-inter .views-row-edit-edit label, .path-score-final-women-inter .views-row-edit-edit label, .path-score-final-men-open .views-row-edit-edit label, .path-score-final-women-open .views-row-edit-edit label,
.path-score-semi-none .views-row-edit-edit label, .path-score-semi-men-inter .views-row-edit-edit label, .path-score-semi-women-inter .views-row-edit-edit label, .path-score-semi-men-open .views-row-edit-edit label, .path-score-semi-women-open .views-row-edit-edit label,
.path-score-qua-none .views-row-edit-edit label, .path-score-qua-men-inter .views-row-edit-edit label, .path-score-qua-women-inter .views-row-edit-edit label, .path-score-qua-men-open .views-row-edit-edit label, .path-score-qua-women-open .views-row-edit-edit label,
.path-score-all-none .views-row-edit-edit label, .path-score-all-men-inter .views-row-edit-edit label, .path-score-all-women-inter .views-row-edit-edit label, .path-score-all-men-open .views-row-edit-edit label, .path-score-all-women-open .views-row-edit-edit label {
  display: none;
}

.path-score-qua-edit .views-row-edit-edit select, .path-score-semi-edit .views-row-edit-edit select, .path-score-final-edit .views-row-edit-edit select,
.path-score-qua-rank .views-row-edit-edit select, .path-score-semi-rank .views-row-edit-edit select, .path-score-final-rank .views-row-edit-edit select,
.path-score-final-none .views-row-edit-edit select, .path-score-final-men-inter .views-row-edit-edit select, .path-score-final-women-inter .views-row-edit-edit select, .path-score-final-men-open .views-row-edit-edit select, .path-score-final-women-open .views-row-edit-edit select,
.path-score-semi-none .views-row-edit-edit select, .path-score-semi-men-inter .views-row-edit-edit select, .path-score-semi-women-inter .views-row-edit-edit select, .path-score-semi-men-open .views-row-edit-edit select, .path-score-semi-women-open .views-row-edit-edit select,
.path-score-qua-none .views-row-edit-edit select, .path-score-qua-men-inter .views-row-edit-edit select, .path-score-qua-women-inter .views-row-edit-edit select, .path-score-qua-men-open .views-row-edit-edit select, .path-score-qua-women-open .views-row-edit-edit select,
.path-score-all-none .views-row-edit-edit select, .path-score-all-men-inter .views-row-edit-edit select, .path-score-all-women-inter .views-row-edit-edit select, .path-score-all-men-open .views-row-edit-edit select, .path-score-all-women-open .views-row-edit-edit select {
  width: 72px;
  padding: 10px 0;
}

.path-score-qua-edit .result, .path-score-semi-edit .result, .path-score-final-edit .result,
.path-score-qua-rank .result, .path-score-semi-rank .result, .path-score-final-rank .result,
.path-score-final-none .result, .path-score-final-men-inter .result, .path-score-final-women-inter .result, .path-score-final-men-open .result, .path-score-final-women-open .result,
.path-score-semi-none .result, .path-score-semi-men-inter .result, .path-score-semi-women-inter .result, .path-score-semi-men-open .result, .path-score-semi-women-open .result,
.path-score-qua-none .result, .path-score-qua-men-inter .result, .path-score-qua-women-inter .result, .path-score-qua-men-open .result, .path-score-qua-women-open .result,
.path-score-all-none .result, .path-score-all-men-inter .result, .path-score-all-women-inter .result, .path-score-all-men-open .result, .path-score-all-women-open .result {
  font-size: 1.3em;
  font-weight: 700;
}

.path-score-qua-edit .rank, .path-score-semi-edit .rank, .path-score-final-edit .rank,
.path-score-qua-rank .rank, .path-score-semi-rank .rank, .path-score-final-rank .rank,
.path-score-final-none .rank, .path-score-final-men-inter .rank, .path-score-final-women-inter .rank, .path-score-final-men-open .rank, .path-score-final-women-open .rank,
.path-score-semi-none .rank, .path-score-semi-men-inter .rank, .path-score-semi-women-inter .rank, .path-score-semi-men-open .rank, .path-score-semi-women-open .rank,
.path-score-qua-none .rank, .path-score-qua-men-inter .rank, .path-score-qua-women-inter .rank, .path-score-qua-men-open .rank, .path-score-qua-women-open .rank,
.path-score-all-none .rank, .path-score-all-men-inter .rank, .path-score-all-women-inter .rank, .path-score-all-men-open .rank, .path-score-all-women-open .rank {
  font-weight: 700;
}

.path-score-qua-edit .no input[type="text"], .path-score-semi-edit .no input[type="text"], .path-score-final-edit .no input[type="text"],
.path-score-qua-rank .no input[type="text"], .path-score-semi-rank .no input[type="text"], .path-score-final-rank .no input[type="text"],
.path-score-final-none .no input[type="text"], .path-score-final-men-inter .no input[type="text"], .path-score-final-women-inter .no input[type="text"], .path-score-final-men-open .no input[type="text"], .path-score-final-women-open .no input[type="text"],
.path-score-semi-none .no input[type="text"], .path-score-semi-men-inter .no input[type="text"], .path-score-semi-women-inter .no input[type="text"], .path-score-semi-men-open .no input[type="text"], .path-score-semi-women-open .no input[type="text"],
.path-score-qua-none .no input[type="text"], .path-score-qua-men-inter .no input[type="text"], .path-score-qua-women-inter .no input[type="text"], .path-score-qua-men-open .no input[type="text"], .path-score-qua-women-open .no input[type="text"],
.path-score-all-none .no input[type="text"], .path-score-all-men-inter .no input[type="text"], .path-score-all-women-inter .no input[type="text"], .path-score-all-men-open .no input[type="text"], .path-score-all-women-open .no input[type="text"] {
  width: 60px;
}

.path-score-qua-edit .rfid input[type="text"], .path-score-semi-edit .rfid input[type="text"], .path-score-final-edit .rfid input[type="text"],
.path-score-qua-rank .rfid input[type="text"], .path-score-semi-rank .rfid input[type="text"], .path-score-final-rank .rfid input[type="text"],
.path-score-final-none .rfid input[type="text"], .path-score-final-men-inter .rfid input[type="text"], .path-score-final-women-inter .rfid input[type="text"], .path-score-final-men-open .rfid input[type="text"], .path-score-final-women-open .rfid input[type="text"],
.path-score-semi-none .rfid input[type="text"], .path-score-semi-men-inter .rfid input[type="text"], .path-score-semi-women-inter .rfid input[type="text"], .path-score-semi-men-open .rfid input[type="text"], .path-score-semi-women-open .rfid input[type="text"],
.path-score-qua-none .rfid input[type="text"], .path-score-qua-men-inter .rfid input[type="text"], .path-score-qua-women-inter .rfid input[type="text"], .path-score-qua-men-open .rfid input[type="text"], .path-score-qua-women-open .rfid input[type="text"],
.path-score-all-none .rfid input[type="text"], .path-score-all-men-inter .rfid input[type="text"], .path-score-all-women-inter .rfid input[type="text"], .path-score-all-men-open .rfid input[type="text"], .path-score-all-women-open .rfid input[type="text"] {
  width: 120px;
}

.path-score-qua-edit .qua-score, .path-score-qua-edit .semi-score, .path-score-qua-edit .final-score, .path-score-semi-edit .qua-score, .path-score-semi-edit .semi-score, .path-score-semi-edit .final-score, .path-score-final-edit .qua-score, .path-score-final-edit .semi-score, .path-score-final-edit .final-score,
.path-score-qua-rank .qua-score,
.path-score-qua-rank .semi-score,
.path-score-qua-rank .final-score, .path-score-semi-rank .qua-score, .path-score-semi-rank .semi-score, .path-score-semi-rank .final-score, .path-score-final-rank .qua-score, .path-score-final-rank .semi-score, .path-score-final-rank .final-score,
.path-score-final-none .qua-score,
.path-score-final-none .semi-score,
.path-score-final-none .final-score, .path-score-final-men-inter .qua-score, .path-score-final-men-inter .semi-score, .path-score-final-men-inter .final-score, .path-score-final-women-inter .qua-score, .path-score-final-women-inter .semi-score, .path-score-final-women-inter .final-score, .path-score-final-men-open .qua-score, .path-score-final-men-open .semi-score, .path-score-final-men-open .final-score, .path-score-final-women-open .qua-score, .path-score-final-women-open .semi-score, .path-score-final-women-open .final-score,
.path-score-semi-none .qua-score,
.path-score-semi-none .semi-score,
.path-score-semi-none .final-score, .path-score-semi-men-inter .qua-score, .path-score-semi-men-inter .semi-score, .path-score-semi-men-inter .final-score, .path-score-semi-women-inter .qua-score, .path-score-semi-women-inter .semi-score, .path-score-semi-women-inter .final-score, .path-score-semi-men-open .qua-score, .path-score-semi-men-open .semi-score, .path-score-semi-men-open .final-score, .path-score-semi-women-open .qua-score, .path-score-semi-women-open .semi-score, .path-score-semi-women-open .final-score,
.path-score-qua-none .qua-score,
.path-score-qua-none .semi-score,
.path-score-qua-none .final-score, .path-score-qua-men-inter .qua-score, .path-score-qua-men-inter .semi-score, .path-score-qua-men-inter .final-score, .path-score-qua-women-inter .qua-score, .path-score-qua-women-inter .semi-score, .path-score-qua-women-inter .final-score, .path-score-qua-men-open .qua-score, .path-score-qua-men-open .semi-score, .path-score-qua-men-open .final-score, .path-score-qua-women-open .qua-score, .path-score-qua-women-open .semi-score, .path-score-qua-women-open .final-score,
.path-score-all-none .qua-score,
.path-score-all-none .semi-score,
.path-score-all-none .final-score, .path-score-all-men-inter .qua-score, .path-score-all-men-inter .semi-score, .path-score-all-men-inter .final-score, .path-score-all-women-inter .qua-score, .path-score-all-women-inter .semi-score, .path-score-all-women-inter .final-score, .path-score-all-men-open .qua-score, .path-score-all-men-open .semi-score, .path-score-all-men-open .final-score, .path-score-all-women-open .qua-score, .path-score-all-women-open .semi-score, .path-score-all-women-open .final-score {
  font-size: 20px;
  padding: 5px 0;
  font-weight: 700;
}

.path-score-qua-edit .qua-y-, .path-score-qua-edit .qua-y-N, .path-score-qua-edit .semi-y-, .path-score-qua-edit .semi-y-N, .path-score-semi-edit .qua-y-, .path-score-semi-edit .qua-y-N, .path-score-semi-edit .semi-y-, .path-score-semi-edit .semi-y-N, .path-score-final-edit .qua-y-, .path-score-final-edit .qua-y-N, .path-score-final-edit .semi-y-, .path-score-final-edit .semi-y-N,
.path-score-qua-rank .qua-y-,
.path-score-qua-rank .qua-y-N,
.path-score-qua-rank .semi-y-,
.path-score-qua-rank .semi-y-N, .path-score-semi-rank .qua-y-, .path-score-semi-rank .qua-y-N, .path-score-semi-rank .semi-y-, .path-score-semi-rank .semi-y-N, .path-score-final-rank .qua-y-, .path-score-final-rank .qua-y-N, .path-score-final-rank .semi-y-, .path-score-final-rank .semi-y-N,
.path-score-final-none .qua-y-,
.path-score-final-none .qua-y-N,
.path-score-final-none .semi-y-,
.path-score-final-none .semi-y-N, .path-score-final-men-inter .qua-y-, .path-score-final-men-inter .qua-y-N, .path-score-final-men-inter .semi-y-, .path-score-final-men-inter .semi-y-N, .path-score-final-women-inter .qua-y-, .path-score-final-women-inter .qua-y-N, .path-score-final-women-inter .semi-y-, .path-score-final-women-inter .semi-y-N, .path-score-final-men-open .qua-y-, .path-score-final-men-open .qua-y-N, .path-score-final-men-open .semi-y-, .path-score-final-men-open .semi-y-N, .path-score-final-women-open .qua-y-, .path-score-final-women-open .qua-y-N, .path-score-final-women-open .semi-y-, .path-score-final-women-open .semi-y-N,
.path-score-semi-none .qua-y-,
.path-score-semi-none .qua-y-N,
.path-score-semi-none .semi-y-,
.path-score-semi-none .semi-y-N, .path-score-semi-men-inter .qua-y-, .path-score-semi-men-inter .qua-y-N, .path-score-semi-men-inter .semi-y-, .path-score-semi-men-inter .semi-y-N, .path-score-semi-women-inter .qua-y-, .path-score-semi-women-inter .qua-y-N, .path-score-semi-women-inter .semi-y-, .path-score-semi-women-inter .semi-y-N, .path-score-semi-men-open .qua-y-, .path-score-semi-men-open .qua-y-N, .path-score-semi-men-open .semi-y-, .path-score-semi-men-open .semi-y-N, .path-score-semi-women-open .qua-y-, .path-score-semi-women-open .qua-y-N, .path-score-semi-women-open .semi-y-, .path-score-semi-women-open .semi-y-N,
.path-score-qua-none .qua-y-,
.path-score-qua-none .qua-y-N,
.path-score-qua-none .semi-y-,
.path-score-qua-none .semi-y-N, .path-score-qua-men-inter .qua-y-, .path-score-qua-men-inter .qua-y-N, .path-score-qua-men-inter .semi-y-, .path-score-qua-men-inter .semi-y-N, .path-score-qua-women-inter .qua-y-, .path-score-qua-women-inter .qua-y-N, .path-score-qua-women-inter .semi-y-, .path-score-qua-women-inter .semi-y-N, .path-score-qua-men-open .qua-y-, .path-score-qua-men-open .qua-y-N, .path-score-qua-men-open .semi-y-, .path-score-qua-men-open .semi-y-N, .path-score-qua-women-open .qua-y-, .path-score-qua-women-open .qua-y-N, .path-score-qua-women-open .semi-y-, .path-score-qua-women-open .semi-y-N,
.path-score-all-none .qua-y-,
.path-score-all-none .qua-y-N,
.path-score-all-none .semi-y-,
.path-score-all-none .semi-y-N, .path-score-all-men-inter .qua-y-, .path-score-all-men-inter .qua-y-N, .path-score-all-men-inter .semi-y-, .path-score-all-men-inter .semi-y-N, .path-score-all-women-inter .qua-y-, .path-score-all-women-inter .qua-y-N, .path-score-all-women-inter .semi-y-, .path-score-all-women-inter .semi-y-N, .path-score-all-men-open .qua-y-, .path-score-all-men-open .qua-y-N, .path-score-all-men-open .semi-y-, .path-score-all-men-open .semi-y-N, .path-score-all-women-open .qua-y-, .path-score-all-women-open .qua-y-N, .path-score-all-women-open .semi-y-, .path-score-all-women-open .semi-y-N {
  font-size: 0;
  color: transparent;
}

.path-score-qua-edit .qua-rank, .path-score-qua-edit .semi-rank, .path-score-qua-edit .rank, .path-score-qua-edit .semi-y-Y, .path-score-qua-edit .qua-y-Y, .path-score-qua-edit td.semi-no, .path-score-qua-edit td.final-no, .path-score-semi-edit .qua-rank, .path-score-semi-edit .semi-rank, .path-score-semi-edit .rank, .path-score-semi-edit .semi-y-Y, .path-score-semi-edit .qua-y-Y, .path-score-semi-edit td.semi-no, .path-score-semi-edit td.final-no, .path-score-final-edit .qua-rank, .path-score-final-edit .semi-rank, .path-score-final-edit .rank, .path-score-final-edit .semi-y-Y, .path-score-final-edit .qua-y-Y, .path-score-final-edit td.semi-no, .path-score-final-edit td.final-no,
.path-score-qua-rank .qua-rank,
.path-score-qua-rank .semi-rank,
.path-score-qua-rank .rank,
.path-score-qua-rank .semi-y-Y,
.path-score-qua-rank .qua-y-Y,
.path-score-qua-rank td.semi-no,
.path-score-qua-rank td.final-no, .path-score-semi-rank .qua-rank, .path-score-semi-rank .semi-rank, .path-score-semi-rank .rank, .path-score-semi-rank .semi-y-Y, .path-score-semi-rank .qua-y-Y, .path-score-semi-rank td.semi-no, .path-score-semi-rank td.final-no, .path-score-final-rank .qua-rank, .path-score-final-rank .semi-rank, .path-score-final-rank .rank, .path-score-final-rank .semi-y-Y, .path-score-final-rank .qua-y-Y, .path-score-final-rank td.semi-no, .path-score-final-rank td.final-no,
.path-score-final-none .qua-rank,
.path-score-final-none .semi-rank,
.path-score-final-none .rank,
.path-score-final-none .semi-y-Y,
.path-score-final-none .qua-y-Y,
.path-score-final-none td.semi-no,
.path-score-final-none td.final-no, .path-score-final-men-inter .qua-rank, .path-score-final-men-inter .semi-rank, .path-score-final-men-inter .rank, .path-score-final-men-inter .semi-y-Y, .path-score-final-men-inter .qua-y-Y, .path-score-final-men-inter td.semi-no, .path-score-final-men-inter td.final-no, .path-score-final-women-inter .qua-rank, .path-score-final-women-inter .semi-rank, .path-score-final-women-inter .rank, .path-score-final-women-inter .semi-y-Y, .path-score-final-women-inter .qua-y-Y, .path-score-final-women-inter td.semi-no, .path-score-final-women-inter td.final-no, .path-score-final-men-open .qua-rank, .path-score-final-men-open .semi-rank, .path-score-final-men-open .rank, .path-score-final-men-open .semi-y-Y, .path-score-final-men-open .qua-y-Y, .path-score-final-men-open td.semi-no, .path-score-final-men-open td.final-no, .path-score-final-women-open .qua-rank, .path-score-final-women-open .semi-rank, .path-score-final-women-open .rank, .path-score-final-women-open .semi-y-Y, .path-score-final-women-open .qua-y-Y, .path-score-final-women-open td.semi-no, .path-score-final-women-open td.final-no,
.path-score-semi-none .qua-rank,
.path-score-semi-none .semi-rank,
.path-score-semi-none .rank,
.path-score-semi-none .semi-y-Y,
.path-score-semi-none .qua-y-Y,
.path-score-semi-none td.semi-no,
.path-score-semi-none td.final-no, .path-score-semi-men-inter .qua-rank, .path-score-semi-men-inter .semi-rank, .path-score-semi-men-inter .rank, .path-score-semi-men-inter .semi-y-Y, .path-score-semi-men-inter .qua-y-Y, .path-score-semi-men-inter td.semi-no, .path-score-semi-men-inter td.final-no, .path-score-semi-women-inter .qua-rank, .path-score-semi-women-inter .semi-rank, .path-score-semi-women-inter .rank, .path-score-semi-women-inter .semi-y-Y, .path-score-semi-women-inter .qua-y-Y, .path-score-semi-women-inter td.semi-no, .path-score-semi-women-inter td.final-no, .path-score-semi-men-open .qua-rank, .path-score-semi-men-open .semi-rank, .path-score-semi-men-open .rank, .path-score-semi-men-open .semi-y-Y, .path-score-semi-men-open .qua-y-Y, .path-score-semi-men-open td.semi-no, .path-score-semi-men-open td.final-no, .path-score-semi-women-open .qua-rank, .path-score-semi-women-open .semi-rank, .path-score-semi-women-open .rank, .path-score-semi-women-open .semi-y-Y, .path-score-semi-women-open .qua-y-Y, .path-score-semi-women-open td.semi-no, .path-score-semi-women-open td.final-no,
.path-score-qua-none .qua-rank,
.path-score-qua-none .semi-rank,
.path-score-qua-none .rank,
.path-score-qua-none .semi-y-Y,
.path-score-qua-none .qua-y-Y,
.path-score-qua-none td.semi-no,
.path-score-qua-none td.final-no, .path-score-qua-men-inter .qua-rank, .path-score-qua-men-inter .semi-rank, .path-score-qua-men-inter .rank, .path-score-qua-men-inter .semi-y-Y, .path-score-qua-men-inter .qua-y-Y, .path-score-qua-men-inter td.semi-no, .path-score-qua-men-inter td.final-no, .path-score-qua-women-inter .qua-rank, .path-score-qua-women-inter .semi-rank, .path-score-qua-women-inter .rank, .path-score-qua-women-inter .semi-y-Y, .path-score-qua-women-inter .qua-y-Y, .path-score-qua-women-inter td.semi-no, .path-score-qua-women-inter td.final-no, .path-score-qua-men-open .qua-rank, .path-score-qua-men-open .semi-rank, .path-score-qua-men-open .rank, .path-score-qua-men-open .semi-y-Y, .path-score-qua-men-open .qua-y-Y, .path-score-qua-men-open td.semi-no, .path-score-qua-men-open td.final-no, .path-score-qua-women-open .qua-rank, .path-score-qua-women-open .semi-rank, .path-score-qua-women-open .rank, .path-score-qua-women-open .semi-y-Y, .path-score-qua-women-open .qua-y-Y, .path-score-qua-women-open td.semi-no, .path-score-qua-women-open td.final-no,
.path-score-all-none .qua-rank,
.path-score-all-none .semi-rank,
.path-score-all-none .rank,
.path-score-all-none .semi-y-Y,
.path-score-all-none .qua-y-Y,
.path-score-all-none td.semi-no,
.path-score-all-none td.final-no, .path-score-all-men-inter .qua-rank, .path-score-all-men-inter .semi-rank, .path-score-all-men-inter .rank, .path-score-all-men-inter .semi-y-Y, .path-score-all-men-inter .qua-y-Y, .path-score-all-men-inter td.semi-no, .path-score-all-men-inter td.final-no, .path-score-all-women-inter .qua-rank, .path-score-all-women-inter .semi-rank, .path-score-all-women-inter .rank, .path-score-all-women-inter .semi-y-Y, .path-score-all-women-inter .qua-y-Y, .path-score-all-women-inter td.semi-no, .path-score-all-women-inter td.final-no, .path-score-all-men-open .qua-rank, .path-score-all-men-open .semi-rank, .path-score-all-men-open .rank, .path-score-all-men-open .semi-y-Y, .path-score-all-men-open .qua-y-Y, .path-score-all-men-open td.semi-no, .path-score-all-men-open td.final-no, .path-score-all-women-open .qua-rank, .path-score-all-women-open .semi-rank, .path-score-all-women-open .rank, .path-score-all-women-open .semi-y-Y, .path-score-all-women-open .qua-y-Y, .path-score-all-women-open td.semi-no, .path-score-all-women-open td.final-no {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
}

.path-score-qua-edit .qua-rank input[type="text"], .path-score-qua-edit .semi-rank input[type="text"], .path-score-qua-edit .rank input[type="text"], .path-score-qua-edit .semi-y-Y input[type="text"], .path-score-qua-edit .qua-y-Y input[type="text"], .path-score-qua-edit td.semi-no input[type="text"], .path-score-qua-edit td.final-no input[type="text"], .path-score-semi-edit .qua-rank input[type="text"], .path-score-semi-edit .semi-rank input[type="text"], .path-score-semi-edit .rank input[type="text"], .path-score-semi-edit .semi-y-Y input[type="text"], .path-score-semi-edit .qua-y-Y input[type="text"], .path-score-semi-edit td.semi-no input[type="text"], .path-score-semi-edit td.final-no input[type="text"], .path-score-final-edit .qua-rank input[type="text"], .path-score-final-edit .semi-rank input[type="text"], .path-score-final-edit .rank input[type="text"], .path-score-final-edit .semi-y-Y input[type="text"], .path-score-final-edit .qua-y-Y input[type="text"], .path-score-final-edit td.semi-no input[type="text"], .path-score-final-edit td.final-no input[type="text"],
.path-score-qua-rank .qua-rank input[type="text"],
.path-score-qua-rank .semi-rank input[type="text"],
.path-score-qua-rank .rank input[type="text"],
.path-score-qua-rank .semi-y-Y input[type="text"],
.path-score-qua-rank .qua-y-Y input[type="text"],
.path-score-qua-rank td.semi-no input[type="text"],
.path-score-qua-rank td.final-no input[type="text"], .path-score-semi-rank .qua-rank input[type="text"], .path-score-semi-rank .semi-rank input[type="text"], .path-score-semi-rank .rank input[type="text"], .path-score-semi-rank .semi-y-Y input[type="text"], .path-score-semi-rank .qua-y-Y input[type="text"], .path-score-semi-rank td.semi-no input[type="text"], .path-score-semi-rank td.final-no input[type="text"], .path-score-final-rank .qua-rank input[type="text"], .path-score-final-rank .semi-rank input[type="text"], .path-score-final-rank .rank input[type="text"], .path-score-final-rank .semi-y-Y input[type="text"], .path-score-final-rank .qua-y-Y input[type="text"], .path-score-final-rank td.semi-no input[type="text"], .path-score-final-rank td.final-no input[type="text"],
.path-score-final-none .qua-rank input[type="text"],
.path-score-final-none .semi-rank input[type="text"],
.path-score-final-none .rank input[type="text"],
.path-score-final-none .semi-y-Y input[type="text"],
.path-score-final-none .qua-y-Y input[type="text"],
.path-score-final-none td.semi-no input[type="text"],
.path-score-final-none td.final-no input[type="text"], .path-score-final-men-inter .qua-rank input[type="text"], .path-score-final-men-inter .semi-rank input[type="text"], .path-score-final-men-inter .rank input[type="text"], .path-score-final-men-inter .semi-y-Y input[type="text"], .path-score-final-men-inter .qua-y-Y input[type="text"], .path-score-final-men-inter td.semi-no input[type="text"], .path-score-final-men-inter td.final-no input[type="text"], .path-score-final-women-inter .qua-rank input[type="text"], .path-score-final-women-inter .semi-rank input[type="text"], .path-score-final-women-inter .rank input[type="text"], .path-score-final-women-inter .semi-y-Y input[type="text"], .path-score-final-women-inter .qua-y-Y input[type="text"], .path-score-final-women-inter td.semi-no input[type="text"], .path-score-final-women-inter td.final-no input[type="text"], .path-score-final-men-open .qua-rank input[type="text"], .path-score-final-men-open .semi-rank input[type="text"], .path-score-final-men-open .rank input[type="text"], .path-score-final-men-open .semi-y-Y input[type="text"], .path-score-final-men-open .qua-y-Y input[type="text"], .path-score-final-men-open td.semi-no input[type="text"], .path-score-final-men-open td.final-no input[type="text"], .path-score-final-women-open .qua-rank input[type="text"], .path-score-final-women-open .semi-rank input[type="text"], .path-score-final-women-open .rank input[type="text"], .path-score-final-women-open .semi-y-Y input[type="text"], .path-score-final-women-open .qua-y-Y input[type="text"], .path-score-final-women-open td.semi-no input[type="text"], .path-score-final-women-open td.final-no input[type="text"],
.path-score-semi-none .qua-rank input[type="text"],
.path-score-semi-none .semi-rank input[type="text"],
.path-score-semi-none .rank input[type="text"],
.path-score-semi-none .semi-y-Y input[type="text"],
.path-score-semi-none .qua-y-Y input[type="text"],
.path-score-semi-none td.semi-no input[type="text"],
.path-score-semi-none td.final-no input[type="text"], .path-score-semi-men-inter .qua-rank input[type="text"], .path-score-semi-men-inter .semi-rank input[type="text"], .path-score-semi-men-inter .rank input[type="text"], .path-score-semi-men-inter .semi-y-Y input[type="text"], .path-score-semi-men-inter .qua-y-Y input[type="text"], .path-score-semi-men-inter td.semi-no input[type="text"], .path-score-semi-men-inter td.final-no input[type="text"], .path-score-semi-women-inter .qua-rank input[type="text"], .path-score-semi-women-inter .semi-rank input[type="text"], .path-score-semi-women-inter .rank input[type="text"], .path-score-semi-women-inter .semi-y-Y input[type="text"], .path-score-semi-women-inter .qua-y-Y input[type="text"], .path-score-semi-women-inter td.semi-no input[type="text"], .path-score-semi-women-inter td.final-no input[type="text"], .path-score-semi-men-open .qua-rank input[type="text"], .path-score-semi-men-open .semi-rank input[type="text"], .path-score-semi-men-open .rank input[type="text"], .path-score-semi-men-open .semi-y-Y input[type="text"], .path-score-semi-men-open .qua-y-Y input[type="text"], .path-score-semi-men-open td.semi-no input[type="text"], .path-score-semi-men-open td.final-no input[type="text"], .path-score-semi-women-open .qua-rank input[type="text"], .path-score-semi-women-open .semi-rank input[type="text"], .path-score-semi-women-open .rank input[type="text"], .path-score-semi-women-open .semi-y-Y input[type="text"], .path-score-semi-women-open .qua-y-Y input[type="text"], .path-score-semi-women-open td.semi-no input[type="text"], .path-score-semi-women-open td.final-no input[type="text"],
.path-score-qua-none .qua-rank input[type="text"],
.path-score-qua-none .semi-rank input[type="text"],
.path-score-qua-none .rank input[type="text"],
.path-score-qua-none .semi-y-Y input[type="text"],
.path-score-qua-none .qua-y-Y input[type="text"],
.path-score-qua-none td.semi-no input[type="text"],
.path-score-qua-none td.final-no input[type="text"], .path-score-qua-men-inter .qua-rank input[type="text"], .path-score-qua-men-inter .semi-rank input[type="text"], .path-score-qua-men-inter .rank input[type="text"], .path-score-qua-men-inter .semi-y-Y input[type="text"], .path-score-qua-men-inter .qua-y-Y input[type="text"], .path-score-qua-men-inter td.semi-no input[type="text"], .path-score-qua-men-inter td.final-no input[type="text"], .path-score-qua-women-inter .qua-rank input[type="text"], .path-score-qua-women-inter .semi-rank input[type="text"], .path-score-qua-women-inter .rank input[type="text"], .path-score-qua-women-inter .semi-y-Y input[type="text"], .path-score-qua-women-inter .qua-y-Y input[type="text"], .path-score-qua-women-inter td.semi-no input[type="text"], .path-score-qua-women-inter td.final-no input[type="text"], .path-score-qua-men-open .qua-rank input[type="text"], .path-score-qua-men-open .semi-rank input[type="text"], .path-score-qua-men-open .rank input[type="text"], .path-score-qua-men-open .semi-y-Y input[type="text"], .path-score-qua-men-open .qua-y-Y input[type="text"], .path-score-qua-men-open td.semi-no input[type="text"], .path-score-qua-men-open td.final-no input[type="text"], .path-score-qua-women-open .qua-rank input[type="text"], .path-score-qua-women-open .semi-rank input[type="text"], .path-score-qua-women-open .rank input[type="text"], .path-score-qua-women-open .semi-y-Y input[type="text"], .path-score-qua-women-open .qua-y-Y input[type="text"], .path-score-qua-women-open td.semi-no input[type="text"], .path-score-qua-women-open td.final-no input[type="text"],
.path-score-all-none .qua-rank input[type="text"],
.path-score-all-none .semi-rank input[type="text"],
.path-score-all-none .rank input[type="text"],
.path-score-all-none .semi-y-Y input[type="text"],
.path-score-all-none .qua-y-Y input[type="text"],
.path-score-all-none td.semi-no input[type="text"],
.path-score-all-none td.final-no input[type="text"], .path-score-all-men-inter .qua-rank input[type="text"], .path-score-all-men-inter .semi-rank input[type="text"], .path-score-all-men-inter .rank input[type="text"], .path-score-all-men-inter .semi-y-Y input[type="text"], .path-score-all-men-inter .qua-y-Y input[type="text"], .path-score-all-men-inter td.semi-no input[type="text"], .path-score-all-men-inter td.final-no input[type="text"], .path-score-all-women-inter .qua-rank input[type="text"], .path-score-all-women-inter .semi-rank input[type="text"], .path-score-all-women-inter .rank input[type="text"], .path-score-all-women-inter .semi-y-Y input[type="text"], .path-score-all-women-inter .qua-y-Y input[type="text"], .path-score-all-women-inter td.semi-no input[type="text"], .path-score-all-women-inter td.final-no input[type="text"], .path-score-all-men-open .qua-rank input[type="text"], .path-score-all-men-open .semi-rank input[type="text"], .path-score-all-men-open .rank input[type="text"], .path-score-all-men-open .semi-y-Y input[type="text"], .path-score-all-men-open .qua-y-Y input[type="text"], .path-score-all-men-open td.semi-no input[type="text"], .path-score-all-men-open td.final-no input[type="text"], .path-score-all-women-open .qua-rank input[type="text"], .path-score-all-women-open .semi-rank input[type="text"], .path-score-all-women-open .rank input[type="text"], .path-score-all-women-open .semi-y-Y input[type="text"], .path-score-all-women-open .qua-y-Y input[type="text"], .path-score-all-women-open td.semi-no input[type="text"], .path-score-all-women-open td.final-no input[type="text"] {
  width: 50px;
}

.path-score-qua-edit .qua-check-1:before, .path-score-semi-edit .qua-check-1:before, .path-score-final-edit .qua-check-1:before,
.path-score-qua-rank .qua-check-1:before, .path-score-semi-rank .qua-check-1:before, .path-score-final-rank .qua-check-1:before,
.path-score-final-none .qua-check-1:before, .path-score-final-men-inter .qua-check-1:before, .path-score-final-women-inter .qua-check-1:before, .path-score-final-men-open .qua-check-1:before, .path-score-final-women-open .qua-check-1:before,
.path-score-semi-none .qua-check-1:before, .path-score-semi-men-inter .qua-check-1:before, .path-score-semi-women-inter .qua-check-1:before, .path-score-semi-men-open .qua-check-1:before, .path-score-semi-women-open .qua-check-1:before,
.path-score-qua-none .qua-check-1:before, .path-score-qua-men-inter .qua-check-1:before, .path-score-qua-women-inter .qua-check-1:before, .path-score-qua-men-open .qua-check-1:before, .path-score-qua-women-open .qua-check-1:before,
.path-score-all-none .qua-check-1:before, .path-score-all-men-inter .qua-check-1:before, .path-score-all-women-inter .qua-check-1:before, .path-score-all-men-open .qua-check-1:before, .path-score-all-women-open .qua-check-1:before {
  content: "✔";
  color: #3c763d;
  padding-right: 3px;
}

.path-score-qua-edit .status-none, .path-score-semi-edit .status-none, .path-score-final-edit .status-none,
.path-score-qua-rank .status-none, .path-score-semi-rank .status-none, .path-score-final-rank .status-none,
.path-score-final-none .status-none, .path-score-final-men-inter .status-none, .path-score-final-women-inter .status-none, .path-score-final-men-open .status-none, .path-score-final-women-open .status-none,
.path-score-semi-none .status-none, .path-score-semi-men-inter .status-none, .path-score-semi-women-inter .status-none, .path-score-semi-men-open .status-none, .path-score-semi-women-open .status-none,
.path-score-qua-none .status-none, .path-score-qua-men-inter .status-none, .path-score-qua-women-inter .status-none, .path-score-qua-men-open .status-none, .path-score-qua-women-open .status-none,
.path-score-all-none .status-none, .path-score-all-men-inter .status-none, .path-score-all-women-inter .status-none, .path-score-all-men-open .status-none, .path-score-all-women-open .status-none {
  color: transparent;
  font-size: 0;
}

.path-score-qua-edit .status-top, .path-score-semi-edit .status-top, .path-score-final-edit .status-top,
.path-score-qua-rank .status-top, .path-score-semi-rank .status-top, .path-score-final-rank .status-top,
.path-score-final-none .status-top, .path-score-final-men-inter .status-top, .path-score-final-women-inter .status-top, .path-score-final-men-open .status-top, .path-score-final-women-open .status-top,
.path-score-semi-none .status-top, .path-score-semi-men-inter .status-top, .path-score-semi-women-inter .status-top, .path-score-semi-men-open .status-top, .path-score-semi-women-open .status-top,
.path-score-qua-none .status-top, .path-score-qua-men-inter .status-top, .path-score-qua-women-inter .status-top, .path-score-qua-men-open .status-top, .path-score-qua-women-open .status-top,
.path-score-all-none .status-top, .path-score-all-men-inter .status-top, .path-score-all-women-inter .status-top, .path-score-all-men-open .status-top, .path-score-all-women-open .status-top {
  font-weight: 700;
  background: #ffbf60 !important;
}

.path-score-qua-edit .result-score, .path-score-semi-edit .result-score, .path-score-final-edit .result-score,
.path-score-qua-rank .result-score, .path-score-semi-rank .result-score, .path-score-final-rank .result-score,
.path-score-final-none .result-score, .path-score-final-men-inter .result-score, .path-score-final-women-inter .result-score, .path-score-final-men-open .result-score, .path-score-final-women-open .result-score,
.path-score-semi-none .result-score, .path-score-semi-men-inter .result-score, .path-score-semi-women-inter .result-score, .path-score-semi-men-open .result-score, .path-score-semi-women-open .result-score,
.path-score-qua-none .result-score, .path-score-qua-men-inter .result-score, .path-score-qua-women-inter .result-score, .path-score-qua-men-open .result-score, .path-score-qua-women-open .result-score,
.path-score-all-none .result-score, .path-score-all-men-inter .result-score, .path-score-all-women-inter .result-score, .path-score-all-men-open .result-score, .path-score-all-women-open .result-score {
  position: absolute;
  top: -4px;
  left: 1px;
}

@media screen and (max-width: 992px) {
  .path-score-qua-edit table, .path-score-semi-edit table, .path-score-final-edit table,
  .path-score-qua-rank table, .path-score-semi-rank table, .path-score-final-rank table,
  .path-score-final-none table, .path-score-final-men-inter table, .path-score-final-women-inter table, .path-score-final-men-open table, .path-score-final-women-open table,
  .path-score-semi-none table, .path-score-semi-men-inter table, .path-score-semi-women-inter table, .path-score-semi-men-open table, .path-score-semi-women-open table,
  .path-score-qua-none table, .path-score-qua-men-inter table, .path-score-qua-women-inter table, .path-score-qua-men-open table, .path-score-qua-women-open table,
  .path-score-all-none table, .path-score-all-men-inter table, .path-score-all-women-inter table, .path-score-all-men-open table, .path-score-all-women-open table {
    width: auto;
    position: relative;
    white-space: nowrap;
    display: block;
    overflow-x: auto;
    zoom: 0.8;
  }
}

.path-score-semi-edit table, .path-score-final-edit table {
  zoom: 0.8;
}

.path-score-semi-edit input[type="text"], .path-score-final-edit input[type="text"] {
  width: 30px;
}

.view-display-id-final_route_1 #breadcrumb, .view-display-id-final_route_2 #breadcrumb, .view-display-id-final_route_3 #breadcrumb, .view-display-id-final_route_4 #breadcrumb,
.view-display-id-semi_route_1 #breadcrumb, .view-display-id-semi_route_2 #breadcrumb, .view-display-id-semi_route_3 #breadcrumb, .view-display-id-semi_route_4 #breadcrumb {
  display: none;
}

.view-display-id-final_route_1 table, .view-display-id-final_route_2 table, .view-display-id-final_route_3 table, .view-display-id-final_route_4 table,
.view-display-id-semi_route_1 table, .view-display-id-semi_route_2 table, .view-display-id-semi_route_3 table, .view-display-id-semi_route_4 table {
  width: 100%;
  text-align: center;
}

.view-display-id-final_route_1 input[type="text"], .view-display-id-final_route_2 input[type="text"], .view-display-id-final_route_3 input[type="text"], .view-display-id-final_route_4 input[type="text"],
.view-display-id-semi_route_1 input[type="text"], .view-display-id-semi_route_2 input[type="text"], .view-display-id-semi_route_3 input[type="text"], .view-display-id-semi_route_4 input[type="text"] {
  width: 80%;
  text-align: center;
  min-height: inherit;
  padding: 0;
}

.view-display-id-final_route_1 input[type="submit"], .view-display-id-final_route_2 input[type="submit"], .view-display-id-final_route_3 input[type="submit"], .view-display-id-final_route_4 input[type="submit"],
.view-display-id-semi_route_1 input[type="submit"], .view-display-id-semi_route_2 input[type="submit"], .view-display-id-semi_route_3 input[type="submit"], .view-display-id-semi_route_4 input[type="submit"] {
  display: none;
}

.view-display-id-final_route_1 input[disabled="disabled"], .view-display-id-final_route_2 input[disabled="disabled"], .view-display-id-final_route_3 input[disabled="disabled"], .view-display-id-final_route_4 input[disabled="disabled"],
.view-display-id-semi_route_1 input[disabled="disabled"], .view-display-id-semi_route_2 input[disabled="disabled"], .view-display-id-semi_route_3 input[disabled="disabled"], .view-display-id-semi_route_4 input[disabled="disabled"] {
  background: #808080 !important;
}

.view-display-id-final_route_1 input[type="text"][disabled="disabled"], .view-display-id-final_route_2 input[type="text"][disabled="disabled"], .view-display-id-final_route_3 input[type="text"][disabled="disabled"], .view-display-id-final_route_4 input[type="text"][disabled="disabled"],
.view-display-id-semi_route_1 input[type="text"][disabled="disabled"], .view-display-id-semi_route_2 input[type="text"][disabled="disabled"], .view-display-id-semi_route_3 input[type="text"][disabled="disabled"], .view-display-id-semi_route_4 input[type="text"][disabled="disabled"] {
  background: #d6d6d6 !important;
}

.view-display-id-final_route_1 .views-row-edit-static, .view-display-id-final_route_2 .views-row-edit-static, .view-display-id-final_route_3 .views-row-edit-static, .view-display-id-final_route_4 .views-row-edit-static,
.view-display-id-semi_route_1 .views-row-edit-static, .view-display-id-semi_route_2 .views-row-edit-static, .view-display-id-semi_route_3 .views-row-edit-static, .view-display-id-semi_route_4 .views-row-edit-static {
  display: none;
}

.view-display-id-final_route_1 th, .view-display-id-final_route_2 th, .view-display-id-final_route_3 th, .view-display-id-final_route_4 th,
.view-display-id-semi_route_1 th, .view-display-id-semi_route_2 th, .view-display-id-semi_route_3 th, .view-display-id-semi_route_4 th {
  text-align: center !important;
  padding: 10px;
  background: #FFFFFF;
}

.view-display-id-final_route_1 td, .view-display-id-final_route_2 td, .view-display-id-final_route_3 td, .view-display-id-final_route_4 td,
.view-display-id-semi_route_1 td, .view-display-id-semi_route_2 td, .view-display-id-semi_route_3 td, .view-display-id-semi_route_4 td {
  padding: 10px 0;
  font-size: 1.5em;
}

.view-display-id-final_route_1 td.no, .view-display-id-final_route_2 td.no, .view-display-id-final_route_3 td.no, .view-display-id-final_route_4 td.no,
.view-display-id-semi_route_1 td.no, .view-display-id-semi_route_2 td.no, .view-display-id-semi_route_3 td.no, .view-display-id-semi_route_4 td.no {
  font-weight: 700;
}

.view-display-id-final_route_1 .row3 input[type="text"], .view-display-id-final_route_2 .row3 input[type="text"], .view-display-id-final_route_3 .row3 input[type="text"], .view-display-id-final_route_4 .row3 input[type="text"],
.view-display-id-semi_route_1 .row3 input[type="text"], .view-display-id-semi_route_2 .row3 input[type="text"], .view-display-id-semi_route_3 .row3 input[type="text"], .view-display-id-semi_route_4 .row3 input[type="text"] {
  width: 15%;
}

.view-display-id-final_route_1 .row3 input[type="button"], .view-display-id-final_route_2 .row3 input[type="button"], .view-display-id-final_route_3 .row3 input[type="button"], .view-display-id-final_route_4 .row3 input[type="button"],
.view-display-id-semi_route_1 .row3 input[type="button"], .view-display-id-semi_route_2 .row3 input[type="button"], .view-display-id-semi_route_3 .row3 input[type="button"], .view-display-id-semi_route_4 .row3 input[type="button"] {
  padding-right: 3%;
  padding-left: 3%;
  font-size: 1em;
}

.view-display-id-final_route_1 .row3 input[type="button"].plus, .view-display-id-final_route_2 .row3 input[type="button"].plus, .view-display-id-final_route_3 .row3 input[type="button"].plus, .view-display-id-final_route_4 .row3 input[type="button"].plus,
.view-display-id-semi_route_1 .row3 input[type="button"].plus, .view-display-id-semi_route_2 .row3 input[type="button"].plus, .view-display-id-semi_route_3 .row3 input[type="button"].plus, .view-display-id-semi_route_4 .row3 input[type="button"].plus {
  padding-left: 6%;
  padding-right: 6%;
}

@media screen and (max-width: 480px) {
  .view-display-id-final_route_1 .row3 input.top_button, .view-display-id-final_route_1 .row3 input.zone_button, .view-display-id-final_route_2 .row3 input.top_button, .view-display-id-final_route_2 .row3 input.zone_button, .view-display-id-final_route_3 .row3 input.top_button, .view-display-id-final_route_3 .row3 input.zone_button, .view-display-id-final_route_4 .row3 input.top_button, .view-display-id-final_route_4 .row3 input.zone_button,
  .view-display-id-semi_route_1 .row3 input.top_button,
  .view-display-id-semi_route_1 .row3 input.zone_button, .view-display-id-semi_route_2 .row3 input.top_button, .view-display-id-semi_route_2 .row3 input.zone_button, .view-display-id-semi_route_3 .row3 input.top_button, .view-display-id-semi_route_3 .row3 input.zone_button, .view-display-id-semi_route_4 .row3 input.top_button, .view-display-id-semi_route_4 .row3 input.zone_button {
    font-size: 0.8em;
  }
}

.start-list .remove-start {
  display: none;
}

.start-list .add-start {
  display: inline !important;
}

.start-list table {
  zoom: 2;
  width: 80% !important;
  margin: auto;
}

.start-list .temp_text {
  display: none;
}

.start-list .page-title {
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  #logo img {
    margin: -10px 0;
  }
  #logo img.header--logo {
    width: 20%;
    height: auto;
  }
  #logo img.header--title {
    width: 70%;
    height: auto;
  }
  #main-navigation {
    background: #ff6363;
  }
  #main-navigation.sticky {
    background-image: url(/sites/all/themes/bootpress/images/iclimb2019_logo.png), url(/sites/all/themes/bootpress/images/iclimb2019_title.png);
    background-repeat: no-repeat;
    background-size: auto 50px, auto 40px;
    background-position: 5px 0, 50px 4px;
  }
  #main-navigation .navbar-header > .container {
    background: inherit;
  }
  #main-navigation .navbar-header .nav li {
    background: #b38c53;
  }
  #main-navigation .navbar-header .nav li a, #main-navigation .navbar-header .nav li .nolink {
    color: #FFF;
    border-bottom: 1px solid #795548;
    letter-spacing: 0.5px;
  }
  #main-navigation .navbar-header .nav li.active-trail a, #main-navigation .navbar-header .nav li.active a {
    background: #753c28;
  }
  #main-navigation .navbar-header .nav li:hover {
    background: #3e2f29;
  }
  .path-event .content .field-name-body p {
    font-size: 1em;
    letter-spacing: 0.5px;
    line-height: 1.2;
  }
  .path-event .webform-submit:focus {
    visibility: hidden;
  }
}

/* Start: fix bootstrap nav-ber bug on screen 768px http://stackoverflow.com/a/36289507 */
@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }
  .navbar-left, .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
    overflow-y: auto;
    max-height: 90%;
    max-height: calc(100vh - 50px);
  }
}

/* End: fix bootstrap nav-ber bug on screen 768px */
@media print {
  html {
    zoom: 0.59;
  }
  body {
    min-width: 1600px;
    background: none;
  }
  html, body, #navigation, #header, #content, #secondary-menu, #page {
    height: auto;
    max-width: 100%;
    overflow: auto;
  }
  .container {
    width: auto;
  }
  #header {
    padding: 0 !important;
  }
  #header > .container > .row > div {
    overflow: hidden;
  }
  h1.page-title {
    text-align: center;
    font-size: 50px;
  }
  .view-header {
    visibility: hidden;
  }
  #page-title {
    margin-top: 0;
  }
  thead {
    display: table-header-group;
  }
  input[type="button"], .tabs, .breadcrumb, #header-top, #block-hybridauth-hybridauth {
    display: none;
  }
  a[href]:after {
    content: "";
  }
  body.admin-menu.adminimal-menu:before, #boot-press-to-top {
    display: none !important;
  }
  table {
    margin: 0;
  }
  .block, #main {
    padding: 0;
  }
  .qua-check-1:before {
    content: "" !important;
  }
  #logo img {
    margin: 0;
  }
  #logo img.web-only {
    display: none;
  }
  #logo img.print-only {
    display: block;
  }
  #logo img.header--logo, #logo img.header--title {
    float: left;
  }
  #logo img.header--title {
    margin-top: -20px;
  }
}
